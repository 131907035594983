.pre-line-text {
  white-space: pre-line;
}
.cardHeader {
  flex-grow: unset !important;
}
.cardContent {
  overflow-y: auto;
}
.cardFoot {
  margin-bottom: auto;
  height: 3rem !important;
  flex-grow: 1;
  flex-shrink: unset;
  padding: 0 !important;
}
.card-action {
  height: 100%;
  width: 100%;
  border-radius: 0;
}
.card-action:hover {
  background-color: #3f59e4 !important;
}

.card {
  max-height: 30rem;
}
.ui.card > .extra,
.ui.cards > .card > .extra {
  min-height: 3rem !important;
}

.buttonContainerTooltip {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: left;
  gap: 0.3rem;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 20px;
}
.buttonContainerTooltip > button {
  padding: 0 !important;
}

.MuiPaper-root
  .MuiPaper-elevation
  .MuiPaper-rounded
  .MuiPaper-elevation1
  .MuiCard-root {
  max-width: 300px !important;
}
.buttonContainerFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem !important;
}
.buttonContainerFooter > button {
  width: 100%;
}
.buttonStyleOverflow {
  margin-top: 10px;
}
.buttonStyleOverflow > button {
  padding: 0 10px !important;
  height: 20px;
  background-color: rgb(225, 225, 225);
}
.docLinks {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 10px;
}
