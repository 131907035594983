.custom-divider {
  border: none;
  margin: 1rem 0;
  border-top: 1px solid #ddd;
  width: 100%;
}

.custom-divider--horizontal {
  margin: 1rem auto;
}

.custom-divider--vertical {
  display: inline-block;
  width: 1px;
  height: 100%;
  background-color: #ddd;
  margin: 0 1rem;
}

.custom-divider--section {
  margin: 2rem 0;
  border-top: 2px solid #bbb;
}

.custom-divider--clearing {
  clear: both;
}

.custom-divider--hidden {
  visibility: hidden;
}

.custom-divider--content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 1rem 0;
}

.custom-divider--content::before,
.custom-divider--content::after {
  content: '';
  flex: 1;
  height: 1px;
  background: #ddd;
}

.custom-divider--content::before {
  margin-right: 1rem;
}

.custom-divider--content::after {
  margin-left: 1rem;
}
