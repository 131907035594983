.resourceCount {
  padding-left: 0.5em;
  font-size: 75%;
}

.pre-line-text {
  white-space: pre-line;
}
.skeletonContainer {
  display: flex;
  gap: 1rem !important;
  width: 100%;
  flex-wrap: wrap;
}
div[role='listitem'] > div {
  width: 100%;
  height: 100%;
}

div[role='listitem'] > div > div {
  width: 100%;
  height: 100%;
}
div[role='listitem'] > div > div > div {
  width: 100%;
  height: 100%;
}
div[variant='tile'] {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

div[variant='tile'] > div:nth-last-child(1) {
  margin-top: auto;
}
