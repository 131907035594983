.collaborationModal > .actions {
  background-color: unset !important;
  border-top: none !important;
}

.ui.modal > .content {
  text-align: left;
}

.collaborationModalTrigger {
  color: #cacbcd;
}

.collaborationModalTrigger:hover {
  color: #191919;
  cursor: pointer;
}
.collaboratorContainer {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
.fieldContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.fieldContainer > button {
  margin-top: 10px;
}
