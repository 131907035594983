body {
  font-size: 14px !important;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Aeonik, Noto, Inter;
}
a {
  margin: 0px;
  font: inherit;
  color: rgb(76, 100, 225);
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  color: rgb(46, 64, 165);
  text-decoration: underline;
}

.contentHeader {
  font-family: 'aeonik', sans-serif !important;
  line-height: 2.75rem !important;
  font-size: 2.5rem !important;
}
#app {
  height: 100% !important;
}
.appComponent {
  margin-bottom: 2.5em;
}

input:read-only,
textarea:read-only {
  background-color: ghostwhite !important;
}

.noTopMargin {
  margin-top: 0 !important;
}

.pre-line-text {
  white-space: pre-line;
}

.refreshControl {
  display: inline !important;
  font-size: 0.4em !important;
  padding-left: 0.5em !important;
  margin-top: -0.5em;
}

.refreshControl:hover {
  cursor: pointer !important;
  color: #3f59e4;
}

.ui.table thead th {
  border-bottom: none;
}

.ComponentMetaRow {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 10px;
}

.inlineIconControl {
  font-size: 0.5em !important;
  vertical-align: top !important;
}

.inlineIconControl:hover {
  cursor: pointer !important;
  color: #3f59e4;
}

.inlineInput > input {
  padding: 0px !important;
}

.padded {
  padding-left: 1em;
  padding-right: 1em;
}

.componentHeader {
  padding-bottom: 1rem !important;
}

.buttonLink {
  background: transparent;
  border: none;
  cursor: pointer;
  color: rgb(39, 39, 39) !important;
  text-decoration: underline;
  margin-top: -1rem;
  font-size: 16px;
}

.buttonLink:hover {
  color: rgb(110, 110, 110) !important;
}
.loadingContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: auto;
  justify-content: center;
  align-items: center;
  height: 100%;
}
