.custom-container {
  margin: 0 auto;
  max-width: 1200px;
  box-sizing: border-box;
}

.custom-container--fluid {
  max-width: 100%;
}

.custom-container--left {
  text-align: left;
}

.custom-container--right {
  text-align: right;
}

.custom-container--center {
  text-align: center;
}

.custom-container--justify {
  text-align: justify;
}
