.buttonContainer {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1rem;
}

.MuiPaper-root MuiPaper-elevation {
  width: 500px;
}
#instanceConfig {
  max-width: 100% !important;
}
