.custom-image {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.custom-image--tiny {
  width: 50px;
}

.custom-image--small {
  width: 100px;
}

.custom-image--medium {
  width: 150px;
}

.custom-image--large {
  width: 250px;
}

.custom-image--big {
  width: 350px;
}

.custom-image--huge {
  width: 500px;
}

.custom-image--massive {
  width: 800px;
}

.custom-image--centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.custom-image--rounded {
  border-radius: 8px;
}

.custom-image--circular {
  border-radius: 50%;
}

.custom-image--bordered {
  border: 2px solid #ddd;
  padding: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
