.custom-card-group {
  display: grid;
  gap: 1rem;
  margin: 1rem 0;
}

.custom-card-group > div {
  max-width: 360px !important;
  min-width: 250px;
}
.custom-card-group > div > div {
  height: 100% !important;
}
.custom-card-group > div > div > div {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  height: 100% !important;
  width: 100% !important;
}

.custom-card-group--2-per-row {
  grid-template-columns: repeat(2, 1fr);
}

.custom-card-group--3-per-row {
  grid-template-columns: repeat(3, 1fr);
}

.custom-card-group--4-per-row {
  grid-template-columns: repeat(4, 1fr);
}

.custom-card-group--5-per-row {
  grid-template-columns: repeat(5, 1fr);
}

.custom-card-group--stackable {
  grid-template-columns: 1fr;
}

@media (min-width: 768px) {
  .custom-card-group--stackable {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
