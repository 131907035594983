.headerSkeleton {
  display: flex;
  justify-content: space-between;
}
.mainSkeleton {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.headerSkeleton {
  display: flex;
  margin: 0 4rem;
  flex-direction: column;
  gap: 2rem;
}
.headingSkeleton {
  margin: 0 2.5rem;
}
.headerSkeletonStack {
  display: flex;
  gap: 0rem;
}
