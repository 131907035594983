.collaborator {
  border: 2px white solid;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white !important;
  color: white;
}

.collaborator > .icon {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.collaborator:not(:first-of-type) {
  margin-left: -15px;
}

.collaboratorAvatars {
  margin-top: -10px !important;
}
