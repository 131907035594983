.cardHeader {
  flex-grow: unset !important;
}

.cardContent {
  overflow-y: auto;
}

.cardFoot {
  margin-bottom: auto;
  height: 3rem !important;
  flex-grow: 1;
  flex-shrink: unset;
  padding: 0 !important;
}

.card-action {
  height: 100%;
  width: 100%;
  border-radius: 0;
  border: none !important;
}

.card-action:hover {
  background-color: #3f59e4 !important;
}

.card {
  max-height: 30rem;
}

.ui.card > .extra,
.ui.cards > .card > .extra {
  min-height: 3rem !important;
}

.buttonContainerTooltip {
  display: flex;
  flex-direction: row;
  height: 40px;
}
.buttonContainerTooltip > button {
  padding: 0 !important;
}

.MuiPaper-root
  .MuiPaper-elevation
  .MuiPaper-rounded
  .MuiPaper-elevation1
  .MuiCard-root {
  max-width: 300px !important;
}
.buttonContainerFooter > button {
  width: 100%;
}
.skeletonContainer {
  display: flex;
  gap: 1rem !important;
  flex-wrap: wrap;
}
.titleAndButtonContainer {
  display: flex;
  justify-content: space-between;
}
