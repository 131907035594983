.custom-header {
  font-family: Arial, sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  padding: 10px 0;
  text-align: justify;
}

.custom-header-subheader {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #777;
  margin-top: 5px;
  text-align: justify;
}
