.rowAction {
  width: 100%;
}

.ui.disabled.dropdown {
  opacity: unset !important;
  background-color: ghostwhite !important;
}

.ui.table thead th {
  padding: 0 0.78571429em !important;
}
.addButtonComponent > button {
  margin-top: 23px !important;
}
.nameField {
  justify-content: center !important;
  align-content: center !important;
}
