.settingContainer {
  display: grid;
  gap: 10px;
  width: 50%;
}
.settingsRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 10px;
}

.cell {
  padding: 8px;
}
