#custom-table {
  display: grid;
  gap: 10px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr;
  align-items: center;
  gap: 10px;
}

.cell {
  padding: 8px;
}
.alternate {
  background-color: rgb(244, 244, 244);
}
