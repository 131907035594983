.metaDataTable {
  display: grid;
}
.metaDataRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  gap: 10px;
}

.metaDataCell {
  padding: 8px;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.headCell {
  font-weight: 700;
}
