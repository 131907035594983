.ui.table tr td {
  border-top: 0px !important;
}

.ui.table thead th {
  padding-bottom: 0.1em !important;
}

.ui.compact.table td {
  padding-top: 0.1em;
}

.ui.accordion:not(.styled) .title ~ .content:not(.ui):last-child {
  padding-bottom: 1em;
}

.scrollable {
  max-height: 290px;
  overflow-y: auto;
  margin-bottom: 15px;
}

.opportunityDataTableCell {
  padding: 8px;
  white-space: nowrap;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}
.opportunityDataTableRow {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr;
  align-items: center;
  gap: 10px;
}
.opportunityDataTableTable {
  display: grid;
}
