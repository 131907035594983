.card {
  overflow: hidden;
}

.createActionButton {
  margin-bottom: 1em !important;
}
.skeletonContainer {
  display: flex !important;
  gap: 1rem;
  flex-wrap: wrap !important;
}
