.appCount {
  padding-left: 0.5em;
  font-size: 75%;
}

.pre-line-text {
  white-space: pre-line;
}
.skeletonContainer {
  display: flex;
  gap: 1rem !important;
  flex-wrap: wrap;
}
